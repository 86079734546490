.App {
  height: 100vh;
}

.nav {
  display: flex;
  align-items: center;
  height: 3.5rem;
  padding-left: 1rem;
  background-color: #00e975;
  font-size: 1.5rem;
  font-weight: bold;
  font-style: italic;
  color: #fff;
}